import React from 'react';
import { InfoWindow } from '@react-google-maps/api';
import { tenantMapsPinToBoundPosition } from '../../../util/tenant-init-data';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { TenantInitData } from '../../../models/tenant-init-data';
import { useTenantDetails } from '../../../hooks/use-tenant-details';
import { BookingTenantMarketingImage } from '../booking-tenant-marketing-image/booking-tenant-marketing-image';
import { BookingTenantAddress } from '../booking-tenant-address/booking-tenant-address';

type Props = {
	readonly il8nNext: ReturnType<typeof useI18next>;
	readonly tenant: TenantInitData;
	readonly onClose: () => void;
	readonly onOpenBooking: () => void;
};

export const BookingInfoWindow: React.FC<Props> = ({
	il8nNext,
	tenant,
	onClose,
	onOpenBooking,
}) => {
	const { data: details, loading } = useTenantDetails(tenant.id);

	return (
		<InfoWindow
			onCloseClick={onClose}
			position={tenantMapsPinToBoundPosition(tenant)}
			options={{
				pixelOffset: new google.maps.Size(0, -40),
				disableAutoPan: true,
			}}
		>
			<div className={`w-[200px]`}>
				<BookingTenantMarketingImage
					className={`aspect-[5/3]`}
					details={details}
					loading={loading}
				/>
				<div className={`m-2`}>
					<h3 className={`text-base font-bold`}>{tenant.displayName}</h3>
					<div className={`my-2`}>
						<BookingTenantAddress details={details} loading={loading} />
					</div>
					<button
						className={`button w-full whitespace-nowrap bg-primary p-4 text-white`}
						onClick={onOpenBooking}
					>
						{il8nNext.t('Book now')}
					</button>
				</div>
			</div>
		</InfoWindow>
	);
};
