import React, { useState } from 'react';
import logo from '../../images/logo.svg';
import Navigation, { NavigationLink } from '../navigation/navigation';
import { buildPath } from '../../config/routes';
import { motion } from 'framer-motion';
import { useI18next } from 'gatsby-plugin-react-i18next/dist/useI18next';
import { StartpageSectionId } from '../../pages';
import { footerId } from '../footer/footer';
import { GoogleAnalyticsEvenName, gtmPushEvent } from '../../util/gtm';

interface Props {
	header: Queries.HeaderFragment;
	il8nNext: ReturnType<typeof useI18next>;
	headerShowBookingButton?: boolean;
	isTenant?: boolean;
}

const Header: React.FC<Props> = ({
	header,
	il8nNext,
	headerShowBookingButton,
	isTenant,
}) => {
	const { originalPath, t } = il8nNext;

	const [showMobileNavigation, setShowMobileNavigation] =
		useState<boolean>(false);

	const headerTranslation = header?.translations?.[0];
	const defaultHeaderLinks: NavigationLink[] = [
		{
			title: headerTranslation?.for_guests_text ?? '',
			path: '/',
			onPageContainerId: StartpageSectionId.ForGuests,
		},
		{
			title: headerTranslation?.for_tenants_text ?? '',
			path: '/',
			onPageContainerId: StartpageSectionId.ForTenants,
		},
		{
			title: header.translations?.[0]?.contact_text ?? '',
			path: originalPath,
			onPageContainerId: footerId,
		},
	];

	const navigationView = (
		<>
			<Navigation
				il8nNext={il8nNext}
				links={defaultHeaderLinks}
				hideMobileNavigation={() => setShowMobileNavigation(false)}
			/>
			{headerShowBookingButton && (
				<a
					onClick={() => {
						gtmPushEvent(GoogleAnalyticsEvenName.HeaderNavigationClickBookNow);
					}}
					className={'button bg-white px-6 py-3 text-center text-primary'}
					href={buildPath(
						header.link_button?.page?.page_tree?.path,
						header.link_button?.page?.path
					)}
				>
					{isTenant
						? t('Find renters')
						: header.link_button?.translations?.[0]?.text}
				</a>
			)}
		</>
	);

	const burger = (
		<div className="space-y-1">
			<div className="h-0.5 w-4 bg-white"></div>
			<div className="h-0.5 w-4 bg-white"></div>
			<div className="h-0.5 w-4 bg-white"></div>
		</div>
	);

	return (
		<header
			className={`fixed top-0 z-30 w-full bg-primary/[0.85] backdrop-blur-[10px]`}
		>
			<div className={`flex h-[60px] justify-center py-2`}>
				<div
					className={
						'container-custom flex w-full items-center justify-between'
					}
				>
					<a href={'/'}>
						<img
							className={'h-8 w-auto'}
							src={logo}
							alt={'BeachNow Logo'}
							width="696"
							height="116"
						/>
					</a>
					<button
						className={'flex p-2 md:hidden'}
						onClick={() => {
							setShowMobileNavigation(!showMobileNavigation);
						}}
						aria-label={t('Menu') ?? ''}
					>
						{burger}
					</button>
					<div className={'hidden md:flex'}>{navigationView}</div>
				</div>
			</div>
			<div className={'flex w-full overflow-hidden md:hidden'}>
				<motion.div
					initial={{ height: '0' }}
					animate={showMobileNavigation ? { height: 'auto' } : { height: '0' }}
				>
					<div
						className={
							'flex w-full flex-col justify-items-center space-y-4 p-4'
						}
					>
						{navigationView}
					</div>
				</motion.div>
			</div>
		</header>
	);
};

export default Header;
