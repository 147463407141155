import React, { useEffect, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/layout/layout';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { PageHead } from '../components/page-head/page-head';
import { StoreLink } from '../components/store-link/store-link';
import { PageContext } from '../types/page-context';

export const query = graphql`
	query QrAppStoresPage($language: String!) {
		directus {
			company {
				...BeachNowCompany
			}
			header {
				...Header
			}
			legals {
				...Legals
			}
		}
		locales: allLocale(filter: { ns: { in: ["common"] } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

const QrAppStores: React.FC<PageProps<Queries.QrAppStoresPageQuery>> = ({
	data,
}) => {
	const il8nNext = useI18next();
	const {
		directus: { company, header, legals },
	} = data;

	const [showFallback, setShowFallback] = useState<boolean>(false);

	useEffect(() => {
		if (company?.apple_store_link && company.play_store_link) {
			if (
				navigator.userAgent.indexOf('iPhone') > -1 ||
				navigator.userAgent.indexOf('iPod') > -1 ||
				navigator.userAgent.indexOf('iPad') > -1
			) {
				window.location.assign(company.apple_store_link);
			} else if (/Android/.test(navigator.userAgent)) {
				window.location.assign(company.play_store_link);
			} else {
				setShowFallback(true);
			}
		}
	}, [company]);

	return company && showFallback ? (
		<Layout il8nNext={il8nNext} header={header} legals={legals}>
			<section
				className={
					'flex h-screen w-screen flex-col items-center justify-center bg-primary'
				}
			>
				<h1 className={'h1 text-white'}>BeachNow App</h1>
				<div className={`flex gap-6`}>
					<StoreLink store="apple" link={company.apple_store_link} />
					<StoreLink store="google" link={company.play_store_link} />
				</div>
			</section>
		</Layout>
	) : null;
};

export default QrAppStores;

export const Head: React.FC<
	PageProps<Queries.QrAppStoresPageQuery, PageContext>
> = ({ pageContext }) => {
	const { language } = pageContext;

	return (
		<PageHead
			title={'BeachNow App'}
			description={'BeachNow App'}
			language={language}
		/>
	);
};
