import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import StartpageIntro from '../components/startpage-intro/startpage-intro';
import StartpageContentSections from '../components/startpage-content-sections/startpage-content-sections';
import ImageCardLinkList from '../components/image-card-link-list/image-card-link-list';
import StartpageTenantsInfo from '../components/startpage-tenants-info/startpage-tenants-info';
import Faq from '../components/faq/faq';
import ContentSection from '../components/content-section/content-section';
import Layout from '../components/layout/layout';
import { useI18next } from 'gatsby-plugin-react-i18next/dist/useI18next';
import { PageHead } from '../components/page-head/page-head';
import { PageContext } from '../types/page-context';

export const query = graphql`
	query Startpage($language: String!) {
		directus {
			startpage {
				...StartpageIntro
				...StartpageContentSections
				...StartpageTenant
				page {
					...Pages
				}
				content_section {
					...ContentSections
				}
			}
			locations {
				...Locations
			}
			faq {
				...FAQ
			}
			company {
				...BeachNowCompany
			}
			header {
				...Header
			}
			legals {
				...Legals
			}
		}
		locales: allLocale(filter: { ns: { in: ["common", "index"] } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export enum StartpageSectionId {
	ForGuests = 'forGuests',
	ForTenants = 'forTenants',
}

const IndexPage: React.FC<PageProps<Queries.StartpageQuery>> = ({ data }) => {
	const { startpage, locations, legals, faq, company, header } = data.directus;
	const il8nNext = useI18next();
	const faqTranslations = faq.flatMap(
		(translations) => translations.translations
	);

	return (
		<Layout il8nNext={il8nNext} header={header} legals={legals}>
			{startpage ? (
				<>
					<StartpageIntro
						company={company}
						intro_image={startpage.intro_image}
						translations={startpage.translations}
					/>
					{startpage.content_sections && (
						<div id={StartpageSectionId.ForGuests}>
							<StartpageContentSections
								content_sections={startpage.content_sections}
							/>
						</div>
					)}
					<ImageCardLinkList
						headline={il8nNext.t('Our Locations')}
						listData={locations}
						h1HeadlineStyle
						withBackgroundColor={true}
					/>
					<div id={StartpageSectionId.ForTenants}>
						<StartpageTenantsInfo
							tenants_info_image={startpage.tenants_info_image}
							translations={startpage.translations}
						/>
					</div>
					<Faq translations={faqTranslations} />
					{startpage.content_section && (
						<section className={'py-container-custom bg-secondary'}>
							<div className={'container-custom'}>
								<ContentSection {...startpage.content_section} />
							</div>
						</section>
					)}
				</>
			) : null}
		</Layout>
	);
};

export default IndexPage;

export const Head: React.FC<PageProps<Queries.StartpageQuery, PageContext>> = ({
	data,
	pageContext,
}) => {
	const page = data.directus.startpage?.page;
	const { language } = pageContext;

	return page ? (
		<PageHead
			title={page.translations?.[0]?.title ?? ''}
			description={page.translations?.[0]?.description ?? ''}
			language={language}
		/>
	) : null;
};
