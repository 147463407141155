export const buildPath = (
	basePath: string | null | undefined,
	path: string | null | undefined
) => `${basePath ?? ''}${path ?? ''}`;

export const appRoutes = {
	qr: {
		basePath: `/qr`,
		pages: {
			appStores: {
				path: `/app-stores`,
			},
		},
	},
};
