import React from 'react';

type Props = {
	readonly loading: boolean;
	readonly placeholderWidth: number;
};

export const BookingTextSkeleton: React.FC<React.PropsWithChildren<Props>> = ({
	children,
	loading,
	placeholderWidth,
}) => {
	if (loading) {
		return (
			<span
				className={`mt-[0.125em] inline-block h-[0.875em] animate-pulse rounded-sm bg-grey`}
				style={{ width: `${placeholderWidth}em` }}
			></span>
		);
	}
	return <>{children}</>;
};
