import * as React from 'react';
import { graphql } from 'gatsby';
import AnimatedContainer from '../animated-container/animated-container';
import { parseDirectusWYSIWYG } from '../../util/utils';
import { motion } from 'framer-motion';
import arrow from '../../images/arrow.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const query = graphql`
	fragment FAQ on DirectusData_faq {
		translations(filter: { languages_code: { code: { _eq: $language } } }) {
			id
			question
			answer
		}
	}
`;

const Faq: React.FC<Queries.FAQFragment> = ({ translations: faqElements }) => {
	const [visibleFaqElementId, setVisibleFaqElementId] = React.useState<
		string | null
	>(null);
	const { t } = useTranslation();

	const faqQuestionClickHandler = (faqElementId: string) =>
		visibleFaqElementId === faqElementId
			? setVisibleFaqElementId(null)
			: setVisibleFaqElementId(faqElementId);

	return (
		<section className={'py-container-custom grid bg-secondary'}>
			<AnimatedContainer className={'container-custom overflow-hidden'}>
				<h2 className={'h1 text-center'}>
					{t('Frequently Asked Questions (FAQs)')}
				</h2>
				{faqElements && (
					<div>
						{faqElements.map(
							(faqElement) =>
								faqElement && (
									<div key={faqElement.id}>
										{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,
										                             jsx-a11y/click-events-have-key-events */}
										<div
											className={
												'mb-2 flex cursor-pointer select-none justify-between border-b-[1px] border-black pb-7 pt-5 font-semibold'
											}
											onClick={() => faqQuestionClickHandler(faqElement.id)}
										>
											{faqElement.question}
											<motion.img
												className={'mx-4 w-6'}
												initial={{ transform: 'rotate(0)' }}
												animate={
													visibleFaqElementId === faqElement.id
														? { transform: 'rotate(180deg)' }
														: { transform: 'rotate(0)' }
												}
												src={arrow}
												alt={
													t(
														visibleFaqElementId === faqElement.id
															? 'Expand'
															: 'Collapse'
													) ?? ''
												}
											/>
										</div>
										<motion.div
											className={'box-border overflow-hidden'}
											key={faqElement.id}
											initial={{ height: '0' }}
											animate={
												visibleFaqElementId === faqElement.id
													? { height: 'auto' }
													: { height: '0' }
											}
										>
											{faqElement.answer
												? parseDirectusWYSIWYG(faqElement.answer)
												: ''}
										</motion.div>
									</div>
								)
						)}
					</div>
				)}
			</AnimatedContainer>
		</section>
	);
};

export default Faq;
