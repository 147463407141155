import { useEffect, useMemo, useState } from 'react';

export const useMediaQuery = (input: string) => {
	const mediaQuery = useMemo(
		() => (typeof window !== 'undefined' ? window?.matchMedia(input) : null),
		[input]
	);
	const [matches, setMatches] = useState(mediaQuery?.matches);
	useEffect(() => {
		const listener = () => setMatches(mediaQuery?.matches);
		mediaQuery?.addEventListener('change', listener);
		return () => mediaQuery?.removeEventListener('change', listener);
	}, [mediaQuery]);
	return matches ?? false;
};
