import { z } from 'zod';

export type TenantInitData = z.infer<typeof tenantInitDataSchema>;

export const tenantInitDataSchema = z.object({
	id: z.string(),
	displayName: z.string(),
	mapsPinLat: z.number(),
	mapsPinLng: z.number(),
	isExternalTenant: z.boolean(),
	externalBookingUrl: z.string().nullable(),
});

export const tenantInitResponseSchema = z.object({
	tenants: z.array(tenantInitDataSchema),
});
