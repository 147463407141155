import { useMediaQuery } from './use-media-query';
import { theme } from '../../tailwind.config';

const getBreakpointFromString = (breakpoint: string | undefined): number =>
	breakpoint ? Number(breakpoint?.replace('px', '')) : 0;

const tailwindBreakpoints = {
	sm: getBreakpointFromString(theme?.screens?.sm),
	md: getBreakpointFromString(theme?.screens?.md),
	lg: getBreakpointFromString(theme?.screens?.lg),
};

const breakpoints = {
	sm: { min: tailwindBreakpoints.sm, max: tailwindBreakpoints.md },
	md: { min: tailwindBreakpoints.md, max: tailwindBreakpoints.lg },
	lg: { min: tailwindBreakpoints.lg, max: null },
};

export const useBreakpoint = (
	key: keyof typeof breakpoints,
	mode?: 'up' | 'down'
) => {
	const breakpoint = breakpoints[key];
	const queries = [
		mode !== 'down' && `(min-width: ${breakpoint.min}px)`,
		mode !== 'up' &&
			breakpoint.max !== null &&
			`(max-width: ${breakpoint.max}px)`,
	];
	return useMediaQuery(queries.filter(Boolean).join(' and '));
};
