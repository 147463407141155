import { useContext, useEffect, useState } from 'react';
import { TenantDetails } from '../models/tenant-details';
import { TenantLoaderContext } from '../components/booking/tenant-loader-provider/tenant-loader-provider';

type UseTenantDetailsResult = {
	data: TenantDetails | null;
	error: unknown;
	loading: boolean;
};

export const useTenantDetails = (tenantId: string): UseTenantDetailsResult => {
	const [data, setData] = useState<TenantDetails | null>(null);
	const [error, setError] = useState<unknown>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const tenantLoader = useContext(TenantLoaderContext);
	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		tenantLoader
			.fetchDetails(tenantId)
			.then((details) => {
				if (!controller.signal.aborted) {
					setData(details);
				}
			})
			.catch(setError)
			.finally(() => setLoading(false));
		return () => controller.abort();
	}, [tenantLoader, tenantId]);

	return { data, error, loading };
};
