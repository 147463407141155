import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageHead } from '../components/page-head/page-head';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout/layout';
import { TenantLoaderProvider } from '../components/booking/tenant-loader-provider/tenant-loader-provider';
import { TenantContentContainer } from '../components/tenant-content/tenant-content-container';
import { PageContext } from '../types/page-context';

export const query = graphql`
	query TenantPage($id: String!, $language: String!) {
		directus {
			thisTenant: tenants(filter: { id: { _eq: $id } }, limit: 1) {
				...Tenants
			}
			otherTenants: tenants(filter: { id: { _neq: $id } }) {
				...Tenants
			}
			header {
				...Header
			}
			legals {
				...Legals
			}
		}
		locales: allLocale(filter: { ns: { in: ["common"] } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

const Tenant: React.FC<PageProps<Queries.TenantPageQuery>> = ({ data }) => {
	const il8nNext = useI18next();
	const {
		directus: { thisTenant, otherTenants, legals, header },
	} = data;
	const tenant = thisTenant?.[0];

	return (
		<Layout il8nNext={il8nNext} header={header} isTenant legals={legals}>
			<TenantLoaderProvider>
				<TenantContentContainer
					il8nNext={il8nNext}
					tenant={tenant}
					otherTenants={otherTenants}
				/>
			</TenantLoaderProvider>
		</Layout>
	);
};

export default Tenant;

export const Head: React.FC<
	PageProps<Queries.TenantPageQuery, PageContext>
> = ({ data, pageContext }) => {
	const page = data.directus.thisTenant?.[0]?.page;
	const { language } = pageContext;

	return page ? (
		<PageHead
			title={page.translations?.[0]?.title ?? ''}
			description={page.translations?.[0]?.description ?? ''}
			language={language}
		/>
	) : null;
};
