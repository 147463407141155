import * as React from 'react';
import DirectusImage, {
	DirectusTransformationKey,
} from '../directus-image/directus-image';
import { parseDirectusWYSIWYG } from '../../util/utils';
import AnimatedContainer from '../animated-container/animated-container';
import { buildPath } from '../../config/routes';

const ContentSection: React.FC<Queries.ContentSectionsFragment> = (
	contentSection
) => {
	const imageIsLeft = contentSection.image_align === 'left';
	const translations =
		contentSection.translations && contentSection.translations[0];

	return (
		<AnimatedContainer
			className={'inline-grid md:grid-cols-3 md:gap-10'}
			key={contentSection.id}
		>
			<div
				className={
					'order-2 inline-grid justify-items-center self-center md:col-span-2 md:justify-items-start'
				}
			>
				{translations?.headline && (
					<h2 className={'h1 md:text-start'}>{translations.headline}</h2>
				)}
				{translations?.text && (
					<p className={'paragraph'}>
						{parseDirectusWYSIWYG(translations.text)}
					</p>
				)}
				{contentSection.link_button && (
					<a
						href={buildPath(
							contentSection.link_button.page?.page_tree?.path,
							contentSection.link_button.page?.path
						)}
						className={'button-primary m-button px-12'}
					>
						{contentSection.link_button.translations?.[0]?.text}
					</a>
				)}
			</div>

			<div
				className={
					imageIsLeft
						? 'order-1 justify-self-center md:order-1 md:col-span-1 md:justify-self-start'
						: 'order-1 justify-self-center md:order-3 md:col-span-1 md:justify-self-end'
				}
			>
				{contentSection.image && (
					<DirectusImage
						className={'max-h-104 object-contain'}
						image={contentSection.image}
						transformationKey={DirectusTransformationKey.WebpSize500}
					/>
				)}
			</div>
		</AnimatedContainer>
	);
};

export default ContentSection;
