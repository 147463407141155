import * as React from 'react';
import { graphql } from 'gatsby';
import orderBy from 'lodash.orderby';
import ContentSection from '../content-section/content-section';

export const query = graphql`
	fragment StartpageContentSections on DirectusData_startpage {
		content_sections {
			sort
			content_sections_id {
				...ContentSections
			}
		}
	}
`;

const StartpageContentSections: React.FC<
	Queries.StartpageContentSectionsFragment
> = ({ content_sections: contentSections }) => {
	return contentSections ? (
		<section className={'py-container-custom bg-secondary'}>
			<div className={'container-custom grid gap-14 md:gap-20'}>
				{orderBy(
					contentSections?.map(
						(contentSection) =>
							contentSection?.content_sections_id && (
								<ContentSection
									key={contentSection.content_sections_id.id}
									{...contentSection.content_sections_id}
								/>
							)
					),
					'sort'
				)}
			</div>
		</section>
	) : null;
};

export default StartpageContentSections;
