import * as React from 'react';
import logo from '../../images/logo.svg';
import instagramLogo from '../../images/instagram.svg';
import youtubeLogo from '../../images/youtube.svg';
import twitterLogo from '../../images/twitter.svg';
import facebookLogo from '../../images/facebook.svg';
import Obfuscate from 'react-obfuscate';

import { useI18next } from 'gatsby-plugin-react-i18next';
import { useCompanyData } from '../../hooks/use-company-data';
import { buildPath } from '../../config/routes';
import { StoreLink } from '../store-link/store-link';

interface Props {
	il8nNext: ReturnType<typeof useI18next>;
	legals: Readonly<Queries.LegalsFragment[]> | null;
}

export const footerId = 'footer';

const renderSocial = (link: string, socialLogo: string, alt: string) => (
	<a href={link} target={'_blank'} className={'w-7'} rel="noreferrer">
		<img
			className={'h-full'}
			src={socialLogo}
			alt={alt}
			width="28"
			height="28"
		/>
	</a>
);

const Footer: React.FC<Props> = ({ il8nNext, legals }) => {
	const { t, language, languages, originalPath } = il8nNext;
	const companyData = useCompanyData();
	const date = new Date();

	const [languagePath, setLanguagePath] = React.useState<string>(originalPath);

	React.useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const queryParams = urlSearchParams.toString();

		if (queryParams !== '') setLanguagePath(`${originalPath}?${queryParams}`);
		else setLanguagePath(originalPath);
	}, [originalPath]);

	return (
		<footer className={'relative z-10 bg-dark-grey py-10'} id={footerId}>
			<div className={'container-custom '}>
				<div className={'grid gap-4 md:grid-cols-2'}>
					<div className={'mb-8'}>
						<img
							className={'mx-auto h-14 w-auto md:mx-0'}
							src={logo}
							alt={'BeachNow Logo'}
							width="696"
							height="116"
						/>
						<div className={'mt-8 flex justify-center gap-6 md:justify-normal'}>
							<StoreLink store="apple" link={companyData?.apple_store_link} />
							<StoreLink store="google" link={companyData?.play_store_link} />
						</div>
					</div>
					<div className={'inline-grid justify-center md:justify-end'}>
						<div className={'grid grid-cols-4 gap-6'}>
							{companyData && (
								<>
									{companyData.facebook_link &&
										renderSocial(
											companyData.facebook_link,
											facebookLogo,
											'BeachNow Facebook'
										)}
									{companyData.twitter_link &&
										renderSocial(
											companyData.twitter_link,
											twitterLogo,
											'BeachNow Twitter'
										)}
									{companyData.instagram_link &&
										renderSocial(
											companyData.instagram_link,
											instagramLogo,
											'BeachNow Instagram'
										)}
									{companyData.youtube_link &&
										renderSocial(
											companyData.youtube_link,
											youtubeLogo,
											'BeachNow Youtube'
										)}
								</>
							)}
						</div>
						<div className={'mb-2 mt-8'}>
							<ul className="flex justify-center md:justify-end">
								{languages.map((lng) => (
									<li
										key={lng}
										className={`border-r-[1px] border-r-white px-4 uppercase leading-tight first:pl-0 last:border-0 last:pr-0 hover:text-white ${
											lng === language ? 'text-white' : 'text-grey'
										}`}
									>
										<a href={languagePath} hrefLang={lng}>
											{lng}
										</a>
									</li>
								))}
							</ul>
						</div>
						<div className={'text-center text-white md:text-right'}>
							<h2 className={'h3'}>{t('Contact')}</h2>
							<address className={'not-italic'}>
								{companyData?.corporate_name}
								<br />
								{companyData?.street_name} {companyData?.street_number}
								<br />
								{companyData?.plz} {companyData?.city}
								<br />
								<br />
								{t('Phone')}:{' '}
								<Obfuscate className={'underline'} tel={companyData?.phone} />
								<br />
								{t('Mail')}:{' '}
								<Obfuscate className={'underline'} email={companyData?.email} />
							</address>
						</div>
					</div>
				</div>
				<div className={'mt-6 flex w-full justify-center'}>
					<div
						className={
							'inline-flex flex-col justify-center space-y-3 text-sm text-white md:flex-row md:space-x-4 md:space-y-0'
						}
					>
						<p>
							©{date.getFullYear()} {companyData?.corporate_name}
						</p>
						{legals?.map((legal) => (
							<a
								className={'text-center underline'}
								key={legal.id}
								href={buildPath(legal.page?.page_tree?.path, legal.page?.path)}
							>
								{legal.page?.translations?.[0]?.link_title}
							</a>
						))}
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
