import { MarkerProps, Marker, useGoogleMap } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { z } from 'zod';

type Props = Omit<MarkerProps, 'position'> & {
	readonly address: string;
	readonly centerMap?: boolean;
};

export const AddressMarker: React.FC<Props> = ({
	address,
	centerMap = false,
	...props
}) => {
	const [position, setPosition] = useState<google.maps.LatLng>();

	useEffect(() => {
		const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
		geocoder
			.geocode({ address })
			.then(({ results }) => setPosition(results[0].geometry.location))
			.catch((e) => {
				console.error(
					`Geocode was not successful for the following reason: ${z.coerce
						.string()
						.parse(e)}`
				);
			});
	}, [address]);

	const map = useGoogleMap();
	useEffect(() => {
		if (centerMap && position && map) {
			map.setCenter(position);
			map.setZoom(10);
		}
	}, [centerMap, position, map]);

	if (!position) return null;
	return <Marker position={position} {...props} />;
};
