import { graphql, useStaticQuery } from 'gatsby';

export const useCompanyData = () => {
	const {
		directus: { company },
	} = useStaticQuery<Queries.CompanyQuery>(
		graphql`
			query Company {
				directus {
					company {
						...BeachNowCompany
					}
				}
			}
		`
	);

	return company;
};
