import { z } from 'zod';
import { tenantInitDataSchema } from './tenant-init-data';

export type TenantDetails = z.infer<typeof tenantDetailsSchema>;

export const tenantDetailsSchema = tenantInitDataSchema.and(
	z.object({
		addressLine1: z.string(),
		addressLine2: z.string(),
		websiteUrl: z.string(),
		marketingImageId: z.number().nullable(),
		logoId: z.number(),
		description: z.string(),
		minBookingDate: z.string(),
		maxBookingDate: z.string(),
	})
);
