import React from 'react';
import { TenantDetails } from '../../../models/tenant-details';
import placeholderImage from '../../../images/booking/placeholder.jpg';
import { BACKEND_ICON_URL } from '../../../config/config';

type Props = {
	readonly className?: string;
	readonly details: TenantDetails | null;
	readonly loading: boolean;
};

export const BookingTenantMarketingImage: React.FC<Props> = ({
	className = '',
	details,
	loading,
}) => {
	const url = details?.marketingImageId
		? BACKEND_ICON_URL(details.id, details.marketingImageId)
		: placeholderImage;
	return (
		<div
			className={`${className} w-full  ${
				loading ? 'animate-pulse bg-grey' : ''
			} bg-cover bg-center`}
			style={{
				...(!loading && {
					backgroundImage: `url(${url})`,
				}),
			}}
		/>
	);
};
