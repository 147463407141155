import React from 'react';
import { DIRECTUS_ASSETS_URL } from '../../config/config';

export enum DirectusTransformationKey {
	WebpDefault = 'webp-default',
	WebpHeight1000 = 'webp-h1000',
	WebpWidth1000 = 'webp-w1000',
	WebpSize500 = 'webp-s500',
}

interface Props {
	readonly image: Queries.ImageFragment;
	readonly transformationKey?: DirectusTransformationKey;
	readonly className?: React.ComponentProps<'img'>['className'];
}

const DirectusImage: React.FC<Props> = ({
	image,
	className,
	transformationKey = DirectusTransformationKey.WebpDefault,
}) => {
	const src = `${DIRECTUS_ASSETS_URL}${image.id}/${image.filename_download}?key=${transformationKey}`;
	return (
		<img
			src={src}
			alt={image.title ?? ''}
			width={image.width ?? undefined}
			height={image.height ?? undefined}
			className={className}
			loading="lazy"
		/>
	);
};

export default DirectusImage;
