import React, { createContext, useMemo } from 'react';
import {
	TenantDetails,
	tenantDetailsSchema,
} from '../../../models/tenant-details';
import { BACKEND_INIT_URL, BACKEND_TENANT_URL } from '../../../config/config';
import {
	TenantInitData,
	tenantInitResponseSchema,
} from '../../../models/tenant-init-data';

type TenantLoaderContextValue = {
	readonly fetchInitData: () => Promise<TenantInitData[]>;
	readonly fetchDetails: (tenantId: string) => Promise<TenantDetails>;
};

export const TenantLoaderContext = createContext<TenantLoaderContextValue>({
	fetchInitData: () => Promise.reject('No TenantLoader in context'),
	fetchDetails: () => Promise.reject('No TenantLoader in context'),
});

class TenantLoader implements TenantLoaderContextValue {
	#initDataCache: Promise<TenantInitData[]> | null = null;

	readonly #detailsCache = new Map<string, Promise<TenantDetails>>();

	async #fetchInitData(): Promise<TenantInitData[]> {
		const response = await fetch(BACKEND_INIT_URL);
		const data = tenantInitResponseSchema.parse(await response.json());
		return data.tenants;
	}

	fetchInitData(): Promise<TenantInitData[]> {
		this.#initDataCache ??= this.#fetchInitData();
		return this.#initDataCache;
	}

	async #fetchDetails(tenantId: string): Promise<TenantDetails> {
		const response = await fetch(BACKEND_TENANT_URL(tenantId));
		return tenantDetailsSchema.parse(await response.json());
	}

	fetchDetails(tenantId: string): Promise<TenantDetails> {
		const cachedPromise = this.#detailsCache.get(tenantId);
		if (cachedPromise) return cachedPromise;

		const promise = this.#fetchDetails(tenantId);
		this.#detailsCache.set(tenantId, promise);
		return promise;
	}
}

export const TenantLoaderProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const cache = useMemo(() => new TenantLoader(), []);
	return (
		<TenantLoaderContext.Provider value={cache}>
			{children}
		</TenantLoaderContext.Provider>
	);
};
