import React, { useEffect } from 'react';
import { TenantInitData } from '../../../models/tenant-init-data';
import { BookingTenantDetails } from '../booking-tenant-details/booking-tenant-details';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { BookingIframe } from '../booking-iframe/booking-iframe';
import Close from '../../../images/booking/close.inline.svg';

type Props = {
	readonly il8nNext: ReturnType<typeof useI18next>;
	readonly tenant: TenantInitData;
	readonly onClose: () => void;
};

export const BookingModal: React.FC<Props> = ({
	il8nNext,
	tenant,
	onClose,
}) => {
	// Close the modal when the backdrop is clicked.
	const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	// Close the modal when escape is presses.
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onClose();
			}
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => window.removeEventListener('keydown', handleKeyDown);
	}, [onClose]);

	// Disable scrolling of the body while the modal is open.
	useEffect(() => {
		const oldOverflow = document.body.style.overflow;
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = oldOverflow;
		};
	}, []);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<div
			className={`fixed inset-0 z-30 flex h-full items-center justify-center bg-[black]/50 `}
			onClick={handleBackdropClick}
		>
			<div
				className={`pointer-events-none grid h-full w-full max-w-[1536px] grid-cols-8 grid-rows-1 items-stretch gap-6 sm:h-[90%] sm:p-6`}
			>
				<section
					className={`pointer-events-auto hidden overflow-auto bg-white shadow-lg sm:rounded-xl md:col-span-3 md:block lg:col-span-2`}
				>
					<BookingTenantDetails il8nNext={il8nNext} tenant={tenant} />
				</section>
				<section
					className={`pointer-events-auto relative col-span-8 overflow-clip bg-white px-4 pt-8 shadow-lg sm:rounded-xl md:col-span-5 lg:col-span-6`}
				>
					<button
						className={`
						absolute right-3 top-3 flex h-[30px] w-[30px] items-center justify-center rounded-full bg-white shadow-sm
					`}
						onClick={onClose}
					>
						<Close />
					</button>
					<BookingIframe tenant={tenant} />
				</section>
			</div>
		</div>
	);
};
