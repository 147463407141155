import React from 'react';
import DirectusImage, {
	DirectusTransformationKey,
} from '../directus-image/directus-image';

interface Props {
	text: string;
	image: Queries.ImageFragment;
}

const ImageCard: React.FC<Props> = ({ text, image }) => {
	return (
		<div
			className={
				'overflow-hidden rounded-xxl shadow transition-all hover:translate-y-[-4px]'
			}
		>
			{image && (
				<div className={'h-[20vh]'}>
					<DirectusImage
						className={'h-full w-full object-cover'}
						image={image}
						transformationKey={DirectusTransformationKey.WebpSize500}
					/>
				</div>
			)}
			<div className={'flex min-h-[120px] items-center bg-white p-4'}>
				<h3 className={'h2 text-start'}>{text}</h3>
			</div>
		</div>
	);
};

export default ImageCard;
