import { format, isAfter, subDays } from 'date-fns';

const formatString = 'dd.MM.yyyy';

export const renderBookingDateRange = ({
	minBookingDateString,
	maxBookingDateString,
}: {
	minBookingDateString: string;
	maxBookingDateString: string;
}): string => {
	const maxBookingDate = subDays(new Date(maxBookingDateString), 1);
	const minBookingDate = isAfter(new Date(minBookingDateString), maxBookingDate)
		? maxBookingDate
		: new Date(minBookingDateString);

	return `${format(minBookingDate, formatString)} - ${format(
		maxBookingDate,
		formatString
	)}`;
};
