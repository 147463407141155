import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageHead } from '../components/page-head/page-head';
import Layout from '../components/layout/layout';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { parseDirectusWYSIWYG } from '../util/utils';
import ImageCardLinkList from '../components/image-card-link-list/image-card-link-list';
import { MapComponent } from '../components/map/map-component';
import markerSelectedImage from '../images/booking/marker-selected.png';
import { MapsApiLoader } from '../components/maps-api-loader/maps-api-loader';
import { AddressMarker } from '../components/address-marker/address-marker';
import { PageContext } from '../types/page-context';

export const query = graphql`
	query LocationPage($id: String!, $language: String!) {
		directus {
			thisLocation: locations(filter: { id: { _eq: $id } }, limit: 1) {
				...Locations
			}
			otherLocations: locations(filter: { id: { _neq: $id } }) {
				...Locations
			}
			header {
				...Header
			}
			legals {
				...Legals
			}
		}
		locales: allLocale(filter: { ns: { in: ["common"] } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

const Location: React.FC<PageProps<Queries.LocationPageQuery>> = ({ data }) => {
	const il8nNext = useI18next();
	const { t } = il8nNext;
	const {
		directus: { thisLocation, otherLocations, legals, header },
	} = data;

	const location = thisLocation?.[0];
	const translation = location?.translations?.[0];

	return (
		<Layout il8nNext={il8nNext} header={header} legals={legals}>
			<section className={'relative mt-[60px] h-[50vh]'}>
				<div
					className={
						'absolute z-10 h-full w-full bg-gradient-to-t from-white to-65% '
					}
				/>
				<MapsApiLoader apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY ?? ''}>
					<MapComponent hideMapControls>
						{location?.address && (
							<AddressMarker
								centerMap
								address={location.address}
								icon={markerSelectedImage}
							/>
						)}
					</MapComponent>
				</MapsApiLoader>
			</section>
			<section>
				<div className={'container-custom pb-20 md:pb-14'}>
					{translation?.headline && (
						<h1 className={'h1 w-full md:text-start'}>
							{translation.headline}
						</h1>
					)}
					{translation?.subline && (
						<h2 className={'h1 w-full md:text-start'}>{translation.subline}</h2>
					)}
					{translation?.text && (
						<p className={'paragraph'}>
							{parseDirectusWYSIWYG(translation.text)}
						</p>
					)}
				</div>

				{location?.tenants?.[0] ? (
					<ImageCardLinkList
						headline={t('Book with these renters')}
						listData={location.tenants}
					/>
				) : null}

				{otherLocations?.[0] ? (
					<ImageCardLinkList
						headline={t('Other Locations')}
						listData={otherLocations}
					/>
				) : null}
			</section>
		</Layout>
	);
};

export default Location;

export const Head: React.FC<
	PageProps<Queries.LocationPageQuery, PageContext>
> = ({ data, pageContext }) => {
	const page = data.directus.thisLocation?.[0]?.page;
	const { language } = pageContext;

	return page ? (
		<PageHead
			title={page.translations?.[0]?.title ?? ''}
			description={page.translations?.[0]?.description ?? ''}
			language={language}
		/>
	) : null;
};
