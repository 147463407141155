/** // @type {import('tailwindcss').Config} */

const bookingHeight = [
	'calc(100vh - 135px)',
	'calc(var(--1dvh, 1vh) * 100 - 135px)',
	'calc(100dvh - 135px)',
];

module.exports = {
	content: [
		'./src/pages/**/*.{js,jsx,ts,tsx}',
		'./src/components/**/*.{js,jsx,ts,tsx}',
		'./src/templates/**/*.{js,jsx,ts,tsx}',
		'./src/modules/**/*.{js,jsx,ts,tsx}',
	],
	purge: [
		'./src/pages/**/*.{js,jsx,ts,tsx}',
		'./src/components/**/*.{js,jsx,ts,tsx}',
		'./src/templates/**/*.{js,jsx,ts,tsx}',
		'./src/modules/**/*.{js,jsx,ts,tsx}',
	],
	theme: {
		fontFamily: {
			sans: ['Nunito'],
		},
		screens: {
			sm: '640px',
			md: '768px',
			lg: '1024px',
		},
		extend: {
			boxShadow: {
				DEFAULT: `0 0 24px rgba(66, 66, 66, 0.15)`,
			},
			borderRadius: {
				xl: '16px',
				xxl: '32px',
			},
			colors: {
				primary: '#5D9BD4',
				'primary-dark': '#3c7cb5',
				secondary: '#fff2db',
				black: '#424242',
				grey: '#b3b3b3',
				'dark-grey': '#5e5e5e',
				warning: '#ff4b23',
			},
			spacing: {
				104: '26rem',
			},
			height: {
				header: '60px',
				booking: bookingHeight,
			},
			minHeight: {
				booking: bookingHeight,
			},
			flex: {
				2: '2 2 0%',
			},
		},
	},
	plugins: [],
};
