import React, { useCallback, useState } from 'react';
import { BookingMarkerCluster } from '../booking-marker-cluster/booking-marker-cluster';
import { tenantMapsPinToBoundPosition } from '../../../util/tenant-init-data';
import { BookingTenantsOverview } from '../booking-tenants-overview/booking-tenants-overview';
import { BookingInfoWindow } from '../booking-info-window/booking-info-window';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { TenantInitData } from '../../../models/tenant-init-data';
import { BookingModal } from '../booking-modal/booking-modal';
import { MapComponent } from '../../map/map-component';
import { MapsApiLoader } from '../../maps-api-loader/maps-api-loader';
import { GoogleAnalyticsEvenName, gtmPushEvent } from '../../../util/gtm';

type Props = {
	readonly il8nNext: ReturnType<typeof useI18next>;
	readonly tenants: readonly TenantInitData[];
};

export const BookingContent: React.FC<Props> = ({ il8nNext, tenants }) => {
	// Center the map on the tenants.
	const [map, setMap] = useState<google.maps.Map>();
	const onLoad = (newMap: google.maps.Map) => {
		const bounds = new google.maps.LatLngBounds();
		tenants
			.map(tenantMapsPinToBoundPosition)
			.forEach((position) => bounds.extend(position));
		newMap.setCenter(bounds.getCenter());
		newMap.fitBounds(bounds);
		setMap(newMap);
	};

	// Center map on selected tenant.
	const [activeTenant, setActiveTenant] = useState<TenantInitData | null>(null);
	const focusTenant = useCallback(
		(tenant: TenantInitData) => {
			if (map) {
				const currentZoom = map.getZoom();
				if (currentZoom && currentZoom < 13) map.setZoom(13);
				map.panTo(tenantMapsPinToBoundPosition(tenant));
			}
		},
		[map]
	);
	const selectTenant = useCallback(
		(newActiveTenant: TenantInitData | null) => {
			setActiveTenant(newActiveTenant);
			if (newActiveTenant) {
				focusTenant(newActiveTenant);
			}
		},
		[focusTenant]
	);
	const unselectTenant = useCallback(() => {
		selectTenant(null);
	}, [selectTenant]);

	const [isBookingOpen, setIsBookingOpen] = useState(false);
	const openBooking = useCallback(() => {
		gtmPushEvent(GoogleAnalyticsEvenName.BookingPageClickBookNow);
		setIsBookingOpen(true);
	}, []);
	const closeBooking = useCallback(() => setIsBookingOpen(false), []);

	const modalView = activeTenant && isBookingOpen && (
		<BookingModal
			il8nNext={il8nNext}
			tenant={activeTenant}
			onClose={closeBooking}
		/>
	);
	const mapView = (
		<MapsApiLoader apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY ?? ''}>
			<MapComponent hideMapControls={isBookingOpen} onLoad={onLoad}>
				<BookingMarkerCluster
					tenants={tenants}
					activeTenant={activeTenant}
					onSelectTenant={selectTenant}
				/>

				{activeTenant && !isBookingOpen && (
					<BookingInfoWindow
						il8nNext={il8nNext}
						tenant={activeTenant}
						onClose={unselectTenant}
						onOpenBooking={openBooking}
					/>
				)}
			</MapComponent>
		</MapsApiLoader>
	);
	const tenantsOverview = (
		<BookingTenantsOverview
			il8nNext={il8nNext}
			tenants={tenants}
			activeTenant={activeTenant}
			onSelectTenant={selectTenant}
		/>
	);

	return (
		<>
			{modalView}
			<section className={'mt-[60px] h-booking'}>
				<div className={`fixed h-booking w-full`}>{mapView}</div>
			</section>
			<section
				className={`relative top-0 z-20 ${isBookingOpen ? 'md:hidden' : ''}`}
			>
				{tenantsOverview}
			</section>
		</>
	);
};
