import React from 'react';
import { TenantContent } from './tenant-content';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useTenantInitData } from '../../hooks/use-tenant-init-data';

type Props = {
	readonly il8nNext: ReturnType<typeof useI18next>;
	readonly tenant: Queries.TenantsFragment;
	readonly otherTenants: Readonly<Queries.TenantsFragment[]>;
};

export const TenantContentContainer: React.FC<Props> = ({
	il8nNext,
	tenant,
	otherTenants,
}) => {
	const { data: backendTenants } = useTenantInitData();

	return (
		<TenantContent
			il8nNext={il8nNext}
			tenant={tenant}
			otherTenants={otherTenants}
			backendTenants={backendTenants}
		/>
	);
};
