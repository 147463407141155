import React from 'react';
import { TenantDetails } from '../../../models/tenant-details';
import { BACKEND_ICON_URL } from '../../../config/config';
import { useI18next } from 'gatsby-plugin-react-i18next';

type Props = {
	readonly il8nNext: ReturnType<typeof useI18next>;
	readonly details: TenantDetails | null;
};

export const BookingTenantLogo: React.FC<Props> = ({ il8nNext, details }) => {
	if (!details) return null;
	return (
		<img
			className={`max-h-[75px]`}
			src={BACKEND_ICON_URL(details.id, details.logoId)}
			alt={
				il8nNext.t('Logo of {{tenant}}', { tenant: details.displayName }) ??
				'Logo'
			}
		/>
	);
};
