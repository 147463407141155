import { useJsApiLoader } from '@react-google-maps/api';
import React from 'react';

type Props = {
	readonly apiKey: string;
};

export const MapsApiLoader: React.FC<React.PropsWithChildren<Props>> = ({
	apiKey,
	children,
}) => {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: apiKey,
	});

	if (!isLoaded) return null;
	return <>{children}</>;
};
