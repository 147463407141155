import { TenantInitData } from '../models/tenant-init-data';

export type BoundPosition = {
	lat: number;
	lng: number;
};

export const tenantMapsPinToBoundPosition = (
	tenant: TenantInitData
): BoundPosition => ({
	lat: tenant.mapsPinLat,
	lng: tenant.mapsPinLng,
});
