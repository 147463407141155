import React from 'react';
import { TenantInitData } from '../../../models/tenant-init-data';
import { useTenantDetails } from '../../../hooks/use-tenant-details';
import { BookingTenantMarketingImage } from '../booking-tenant-marketing-image/booking-tenant-marketing-image';
import { BookingTenantAddress } from '../booking-tenant-address/booking-tenant-address';
import { BookingTenantLogo } from '../booking-tenant-logo/booking-tenant-logo';
import Calendar from '../../../images/booking/calendar.inline.svg';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { BookingTextSkeleton } from '../booking-text-skeleton/booking-text-skeleton';
import { renderBookingDateRange } from '../../../util/date';

type Props = {
	readonly il8nNext: ReturnType<typeof useI18next>;
	readonly tenant: TenantInitData;
};

export const BookingTenantDetails: React.FC<Props> = ({ il8nNext, tenant }) => {
	const { data: details, loading } = useTenantDetails(tenant.id);

	return (
		<>
			<BookingTenantMarketingImage
				className={`sticky top-0 aspect-[5/4]`}
				details={details}
				loading={loading}
			/>
			<article className={`relative bg-white p-4`}>
				<h3 className={`text-[24px] font-bold`}>{tenant.displayName}</h3>
				<div className={`mb-4 flex items-center gap-2`}>
					<div className={`flex-2`}>
						<BookingTenantAddress details={details} loading={loading} />
					</div>
					<div className={`flex flex-1 justify-end`}>
						<BookingTenantLogo il8nNext={il8nNext} details={details} />
					</div>
				</div>
				<h4 className={`text-[24px] font-bold`}>{il8nNext.t('Season')}</h4>
				<div className={`mb-4`}>
					<div className={`flex gap-2`}>
						<Calendar />
						<p>
							<BookingTextSkeleton loading={loading} placeholderWidth={20}>
								{details &&
									renderBookingDateRange({
										minBookingDateString: details.minBookingDate,
										maxBookingDateString: details.maxBookingDate,
									})}
							</BookingTextSkeleton>
						</p>
					</div>
				</div>
				{details?.description && (
					<p className={`text-base`}>{details.description}</p>
				)}
			</article>
		</>
	);
};
