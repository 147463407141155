import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout/layout';
import { PageHead } from '../components/page-head/page-head';
import { PageContext } from '../types/page-context';
import { StringParam, useQueryParam } from 'use-query-params';
import { SubmitHandler, useForm } from 'react-hook-form';
import waves from '../images/waves.svg';

export const query = graphql`
	query DirectPage($language: String!) {
		directus {
			header {
				...Header
			}
			legals {
				...Legals
			}
		}
		locales: allLocale(filter: { ns: { in: ["common", "direct"] } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

type FormData = {
	chairId: string;
};

const DirectPage: React.FC<PageProps<Queries.DirectPageQuery>> = ({ data }) => {
	const { legals, header } = data.directus;
	const il8nNext = useI18next();
	const { t } = il8nNext;

	const [chairIdParam] = useQueryParam('chairId', StringParam);
	const [tenantIdParam] = useQueryParam('tenantId', StringParam);

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<FormData>({
		defaultValues: {
			chairId: chairIdParam ?? undefined,
		},
	});

	const submitIsDisabled = !isValid || !tenantIdParam;

	const onSubmit: SubmitHandler<FormData> = ({ chairId }) => {
		if (tenantIdParam) {
			const santitizedTenantId = tenantIdParam
				.replace(/[^a-zA-Z0-9-]/g, '')
				.replace(/-+/g, '-');
			const trimmedChairId = chairId.trim();
			const bookingUrl = new URL(
				`https://${santitizedTenantId}.beachnow.de/Booking/BeachChair/BeachChairDetails`
			);
			bookingUrl.searchParams.append('chairId', trimmedChairId);
			bookingUrl.searchParams.append('backgroundColor', 'ffffff');
			bookingUrl.searchParams.append('beachChairs', 'True');
			bookingUrl.searchParams.append('vouchers', 'False');
			window.location.href = bookingUrl.toString();
		}
	};

	return (
		<Layout il8nNext={il8nNext} legals={legals} header={header}>
			<section
				style={{ backgroundImage: `url(${waves})` }}
				className={`grid min-h-[80vh] items-center justify-center bg-secondary bg-cover bg-bottom pb-20 pt-[60px] md:flex md:min-h-screen`}
			>
				<div className={'p-4'}>
					<h1 className={'h1 md:h1 block text-center text-white'}>
						{t('Book a beach chair')}
					</h1>
					<p className={'text-center text-white'}>
						{t('Enter beach chair number info')}
					</p>

					<form onSubmit={handleSubmit(onSubmit)} className={'mt-8'}>
						<div>
							<span className={'text-white'}>
								{t('Enter beach chair number')}
							</span>
							<input
								className={'input'}
								placeholder={t('e.g. beach chair number') ?? ''}
								autoFocus={true}
								{...register('chairId', {
									required: true,
									pattern: {
										value: /^\s*[A-Za-z0-9]+\s*$/,
										message: t('Please enter a correct beach chair number'),
									},
								})}
							/>
							{errors.chairId && (
								<i className={'flex pt-2 text-sm text-warning'}>
									{errors.chairId.message
										? errors.chairId.message
										: t('Please enter the beach chair number')}
								</i>
							)}
						</div>

						<input
							className={`button mt-8 w-full whitespace-nowrap p-4 text-white hover:cursor-pointer ${
								submitIsDisabled ? 'bg-grey' : 'bg-primary'
							}`}
							type="submit"
							value={t('Continue') ?? ''}
						/>
					</form>
				</div>
			</section>
		</Layout>
	);
};

export default DirectPage;

export const Head: React.FC<
	PageProps<Queries.DirectPageQuery, PageContext>
> = ({ pageContext }) => {
	const { t } = useI18next();
	const { language } = pageContext;

	return (
		<PageHead
			title={`BeachNow – ${t('Direct: Page title')}`}
			description={`BeachNow – ${t('Direct: Page description')}`}
			language={language}
			additionalHtml={
				<meta name="apple-itunes-app" content="app-id=1663447719" />
			}
		/>
	);
};
