import React from 'react';
import { TenantDetails } from '../../../models/tenant-details';
import { BookingTextSkeleton } from '../booking-text-skeleton/booking-text-skeleton';

type Props = {
	readonly details: TenantDetails | null;
	readonly loading: boolean;
};

export const BookingTenantAddress: React.FC<Props> = ({ details, loading }) => {
	return (
		<>
			<address className={`mb-1 not-italic`}>
				<BookingTextSkeleton loading={loading} placeholderWidth={12}>
					{details?.addressLine1}
				</BookingTextSkeleton>
				<br />
				<BookingTextSkeleton loading={loading} placeholderWidth={10}>
					{details?.addressLine2}
				</BookingTextSkeleton>
			</address>
			<BookingTextSkeleton loading={loading} placeholderWidth={13}>
				{details?.websiteUrl && (
					<a
						className={`underline hover:text-primary`}
						href={
							details.websiteUrl.startsWith('https://')
								? details.websiteUrl
								: `https://${details.websiteUrl}`
						}
						target={'_blank'}
						rel="noreferrer"
					>
						{details.websiteUrl}
					</a>
				)}
			</BookingTextSkeleton>
		</>
	);
};
