import React from 'react';
import { TenantInitData } from '../../../models/tenant-init-data';
import { BACKEND_BOOKING_URL } from '../../../config/config';

type Props = {
	readonly tenant: TenantInitData;
};

export const BookingIframe: React.FC<Props> = ({ tenant }) => {
	return (
		<iframe
			key={tenant.id}
			className={`h-full w-full`}
			src={
				tenant.externalBookingUrl && tenant.isExternalTenant
					? tenant.externalBookingUrl
					: BACKEND_BOOKING_URL(tenant.id)
			}
			title={tenant.displayName}
		/>
	);
};
