import React from 'react';
import ImageCard from '../image-card/image-card';
import { buildPath } from '../../config/routes';
import AnimatedContainer from '../animated-container/animated-container';

type ListData = Queries.TenantsFragment | Queries.LocationsFragment;

interface Props {
	readonly headline: string;
	readonly listData: Readonly<(ListData | null)[]>;
	readonly withBackgroundColor?: boolean;
	readonly h1HeadlineStyle?: boolean;
}

const ImageCardLinkList: React.FC<Props> = ({
	headline,
	listData,
	withBackgroundColor = false,
	h1HeadlineStyle = false,
}) => {
	return (
		<section
			className={`${withBackgroundColor ? 'bg-secondary ' : ''}pb-14 md:pb-20`}
		>
			<AnimatedContainer className={'container-custom'}>
				<h2
					className={`${
						h1HeadlineStyle ? 'h1' : 'h3 mb-6'
					} w-full md:text-start`}
				>
					{headline}
				</h2>

				<div className={'grid gap-10 sm:grid-cols-2 md:grid-cols-3'}>
					{listData.map(
						(data) =>
							data && (
								<a
									key={data.id}
									href={buildPath(data.page?.page_tree?.path, data.page?.path)}
								>
									{data.preview_image && (
										<ImageCard
											key={data.id}
											text={data.translations?.[0]?.name ?? ''}
											image={data.preview_image}
										/>
									)}
								</a>
							)
					)}
				</div>
			</AnimatedContainer>
		</section>
	);
};

export default ImageCardLinkList;
