import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageHead } from '../components/page-head/page-head';
import Layout from '../components/layout/layout';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { parseDirectusWYSIWYG } from '../util/utils';
import { PageContext } from '../types/page-context';

export const query = graphql`
	query LegalPage($id: String!, $language: String!) {
		directus {
			thisLegal: legals(filter: { id: { _eq: $id } }, limit: 1) {
				...Legals
			}
			allLegals: legals {
				...Legals
			}
			header {
				...Header
			}
		}
		locales: allLocale(filter: { ns: { in: ["common"] } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

const Legal: React.FC<PageProps<Queries.LegalPageQuery>> = ({ data }) => {
	const il8nNext = useI18next();
	const {
		directus: { thisLegal, allLegals, header },
	} = data;

	const legal = thisLegal?.[0];
	const translation = legal?.translations?.[0];

	return (
		<Layout il8nNext={il8nNext} header={header} legals={allLegals}>
			<header className={'bg-primary pb-8 pt-[60px]'}>
				<div className={'container-custom flex flex-col justify-center'}>
					<h1 className={'h1 mx-auto text-white'}>{translation?.headline}</h1>
					{translation?.subline && (
						<h2 className={'h1 mx-auto text-white'}>{translation.subline}</h2>
					)}
				</div>
			</header>
			<section>
				<div className={'container-custom py-container-custom'}>
					{translation?.text && (
						<div>{parseDirectusWYSIWYG(translation.text)}</div>
					)}
				</div>
			</section>
		</Layout>
	);
};

export default Legal;

export const Head: React.FC<PageProps<Queries.LegalPageQuery, PageContext>> = ({
	data,
	pageContext,
}) => {
	const page = data.directus.thisLegal?.[0]?.page;
	const { language } = pageContext;

	return page ? (
		<PageHead
			title={page.translations?.[0]?.title ?? ''}
			description={page.translations?.[0]?.description ?? ''}
			language={language}
		/>
	) : null;
};
