import React, { useRef } from 'react';
import BeachUmbrellaIcon from '../../../images/booking/beach-umbrella.inline.svg';
import MarkerOutlinesIcon from '../../../images/booking/marker-outlines.inline.svg';
import { TenantInitData } from '../../../models/tenant-init-data';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from '../../../hooks/use-breakpoint';

type Props = {
	readonly il8nNext: ReturnType<typeof useI18next>;
	readonly tenants: readonly TenantInitData[];
	readonly activeTenant: TenantInitData | null;
	readonly onSelectTenant: (tenant: TenantInitData) => void;
};

export const BookingTenantsOverview: React.FC<Props> = ({
	il8nNext,
	tenants,
	activeTenant,
	onSelectTenant,
}) => {
	const isActiveTenant = (tenant: TenantInitData) =>
		tenant.id === activeTenant?.id;

	const isSmallScreen = useBreakpoint('sm', 'down');

	const bottomPaneRef = useRef<HTMLDivElement>(null);
	const collapse = () => window.scrollTo({ top: 0, behavior: 'smooth' });
	const expand = () =>
		bottomPaneRef.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
		});
	const toggle = () => (window.scrollY === 0 ? expand() : collapse());

	const handleSelectTenant = (tenant: TenantInitData) => {
		onSelectTenant(tenant);
		collapse();
	};

	return (
		<div
			className={`bg-white ${
				isSmallScreen
					? 'rounded-t-lg'
					: 'fixed left-5 top-[60px] z-10 mt-5 rounded-lg bg-white shadow-lg'
			}`}
		>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
			<div
				className={`sticky top-[60px] flex items-center gap-3 bg-white px-6 py-4`}
				onClick={isSmallScreen ? toggle : undefined}
			>
				{isSmallScreen && (
					<button
						className={`absolute left-[50%] top-2 ml-[-25px] h-1 w-[50px] rounded-full bg-grey`}
						aria-label="toggle bottom-sheet"
					></button>
				)}
				<BeachUmbrellaIcon />
				<div>
					<h3 className={`text-base font-bold`}>
						{il8nNext.t('Choose renter')}
					</h3>
					<div className={`text-sm text-grey`}>
						{il8nNext.t('{{count}} results', { count: tenants.length })}
					</div>
				</div>
			</div>

			<div ref={bottomPaneRef} className={`px-6 pb-4`}>
				{tenants.map((tenant) => {
					const isActive = isActiveTenant(tenant);
					return (
						<button
							key={tenant.id}
							className={`flex items-center gap-3 py-1 transition-[color] hover:text-primary ${
								isActive ? 'text-primary' : ''
							}`}
							onClick={() => handleSelectTenant(tenant)}
						>
							<MarkerOutlinesIcon
								className={`[&>path]:transition-[fill] [:hover>&>path]:fill-primary ${
									isActive ? '[&>path]:fill-primary' : ''
								}`}
							/>
							{tenant.displayName}
						</button>
					);
				})}
			</div>
		</div>
	);
};
