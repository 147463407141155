import React, { PropsWithChildren, useEffect } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import { useI18next } from 'gatsby-plugin-react-i18next/dist/useI18next';
import { CookieConsent } from 'react-cookie-consent';
import { theme } from '../../../tailwind.config';

interface Props {
	il8nNext: ReturnType<typeof useI18next>;
	header: Queries.HeaderFragment | null;
	legals: Readonly<Queries.LegalsFragment[]> | null;
	headerShowBookingButton?: boolean;
	isTenant?: boolean;
}

const Layout: React.FC<PropsWithChildren<Props>> = ({
	children,
	il8nNext,
	header,
	legals,
	headerShowBookingButton = true,
	isTenant,
}) => {
	const { t } = il8nNext;

	// Without this fix the scroll position won't go back to the top after navigating to a different page
	useEffect(() => {
		if (window.location.hash === '') {
			setTimeout(() => window.scrollTo(0, 0), 0);
		} else {
			history.pushState('', document.title, window.location.pathname);
		}
	}, [il8nNext.path]);

	return (
		<>
			{header && (
				<Header
					il8nNext={il8nNext}
					headerShowBookingButton={headerShowBookingButton}
					header={header}
					isTenant={isTenant}
				/>
			)}
			<main>{children}</main>
			<Footer il8nNext={il8nNext} legals={legals} />
			<CookieConsent
				buttonText="OK"
				style={{
					background: theme?.extend?.colors?.primary,
				}}
				buttonStyle={{ background: 'white', color: 'black', fontSize: '13px' }}
			>
				{t('Cookies')}
			</CookieConsent>
		</>
	);
};

export default Layout;
