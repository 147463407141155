import * as React from 'react';
import { graphql } from 'gatsby';
import waves from '../../images/waves.svg';
import DirectusImage from '../directus-image/directus-image';
import AnimatedContainer from '../animated-container/animated-container';
import { StoreLink } from '../store-link/store-link';

export const query = graphql`
	fragment StartpageIntro on DirectusData_startpage {
		intro_image {
			...Image
		}
		translations(
			filter: { languages_code: { code: { _eq: $language } } }
			limit: 1
		) {
			intro_headline
			intro_text
		}
	}
`;

const StartpageIntro: React.FC<
	Queries.StartpageIntroFragment & {
		company: Queries.StartpageQuery['directus']['company'];
	}
> = ({ translations: translationsProp, intro_image: introImage, company }) => {
	const translations = translationsProp && translationsProp[0];

	return (
		<section
			style={{ backgroundImage: `url(${waves})` }}
			className={`grid bg-secondary bg-cover bg-bottom pb-44 pt-[60px]`}
		>
			<AnimatedContainer
				className={'container-custom grid gap-4 md:grid-cols-3'}
			>
				<div
					className={
						'mt-6 justify-self-center md:order-2 md:col-span-1 md:my-6'
					}
				>
					{introImage && (
						<DirectusImage
							className={'max-w-[13rem] md:max-w-full'}
							image={introImage}
						/>
					)}
				</div>

				<div className={'self-center md:order-1 md:col-span-2'}>
					<h1
						className={
							'h1 w-full text-center text-white md:w-auto md:text-start'
						}
					>
						{translations?.intro_headline}
					</h1>
					<p className={'paragraph text-white'}>{translations?.intro_text}</p>
					<div className={'mt-10 flex justify-center gap-6 md:justify-normal'}>
						<StoreLink store="apple" link={company?.apple_store_link} />
						<StoreLink store="google" link={company?.play_store_link} />
					</div>
				</div>
			</AnimatedContainer>
		</section>
	);
};

export default StartpageIntro;
