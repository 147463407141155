import React, { useCallback } from 'react';
import { Marker, MarkerClusterer } from '@react-google-maps/api';
import markerClusterImage from '../../../images/booking/marker-cluster.png';
import markerInitialImage from '../../../images/booking/marker-initial.png';
import markerSelectedImage from '../../../images/booking/marker-selected.png';
import { tenantMapsPinToBoundPosition } from '../../../util/tenant-init-data';
import { TenantInitData } from '../../../models/tenant-init-data';

type Props = {
	readonly tenants: readonly TenantInitData[];
	readonly activeTenant: TenantInitData | null;
	readonly onSelectTenant: (newActiveTenant: TenantInitData | null) => void;
};

export const BookingMarkerCluster: React.FC<Props> = ({
	tenants,
	activeTenant,
	onSelectTenant,
}) => {
	const isActiveTenant = useCallback(
		(tenant: TenantInitData) => tenant.id === activeTenant?.id,
		[activeTenant]
	);
	const handleMarkerClick = useCallback(
		(tenant: TenantInitData) => {
			onSelectTenant(isActiveTenant(tenant) ? null : tenant);
		},
		[isActiveTenant, onSelectTenant]
	);

	return (
		<MarkerClusterer
			styles={[
				{
					url: markerClusterImage,
					height: 40,
					width: 40,
					anchorText: [-2, 0],
					textSize: 16,
					fontWeight: '700',
					fontFamily: 'Nunito',
				},
			]}
		>
			{(clusterer) => (
				<>
					{tenants.map((tenant) => (
						<Marker
							key={tenant.id}
							position={tenantMapsPinToBoundPosition(tenant)}
							title={tenant.displayName}
							icon={
								isActiveTenant(tenant)
									? markerSelectedImage
									: markerInitialImage
							}
							onClick={() => handleMarkerClick(tenant)}
							clusterer={clusterer}
						/>
					))}
				</>
			)}
		</MarkerClusterer>
	);
};
