exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-direct-tsx": () => import("./../../../src/pages/direct.tsx" /* webpackChunkName: "component---src-pages-direct-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-booking-tsx": () => import("./../../../src/templates/booking.tsx" /* webpackChunkName: "component---src-templates-booking-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-location-tsx": () => import("./../../../src/templates/location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-qr-app-stores-tsx": () => import("./../../../src/templates/qr-app-stores.tsx" /* webpackChunkName: "component---src-templates-qr-app-stores-tsx" */),
  "component---src-templates-tenant-tsx": () => import("./../../../src/templates/tenant.tsx" /* webpackChunkName: "component---src-templates-tenant-tsx" */)
}

