import React from 'react';
import { scrollToContainer } from '../../util/utils';
import { useI18next } from 'gatsby-plugin-react-i18next/dist/useI18next';

export type NavigationRefType = React.MutableRefObject<HTMLElement | null>;

export type NavigationLink = {
	path: string;
	title: string;
	onPageContainerId?: string;
};

interface Props {
	links: NavigationLink[];
	hideMobileNavigation: () => void;
	il8nNext: ReturnType<typeof useI18next>;
}

const Navigation: React.FC<Props> = ({
	links,
	hideMobileNavigation,
	il8nNext,
}) => {
	const { originalPath } = il8nNext;

	const onPageButtonClickHandler = (id?: string) => {
		hideMobileNavigation();
		setTimeout(() => {
			if (id) scrollToContainer(id);
		}, 0);
	};

	return (
		<>
			{links?.map((link, index) => {
				return (
					<a
						key={index}
						href={`${link.path}${
							link.onPageContainerId ? '#' + link.onPageContainerId : ''
						}`}
						onClick={
							link.path === originalPath && link.onPageContainerId
								? (event) => {
										event.preventDefault();
										onPageButtonClickHandler(link.onPageContainerId);
								  }
								: undefined
						}
						className={
							'inline-flex items-center self-stretch px-6 text-white hover:underline'
						}
					>
						{link.title}
					</a>
				);
			})}
		</>
	);
};

export default Navigation;
