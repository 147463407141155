import { GoogleMap, GoogleMapProps } from '@react-google-maps/api';
import React, { useMemo } from 'react';

type Props = GoogleMapProps & {
	readonly hideMapControls: boolean;
};

export const MapComponent: React.FC<React.PropsWithChildren<Props>> = ({
	hideMapControls,
	...props
}) => {
	const options = useMemo<google.maps.MapOptions>(
		() => ({
			gestureHandling: 'greedy',
			zoomControlOptions: {
				position: google.maps.ControlPosition.TOP_RIGHT,
			},
			mapTypeControlOptions: {
				position: google.maps.ControlPosition.TOP_RIGHT,
			},
			scaleControl: false,
			streetViewControl: false,
			fullscreenControl: false,
			mapTypeControl: !hideMapControls,
			zoomControl: !hideMapControls,
			styles: [
				{
					featureType: 'poi',
					stylers: [{ visibility: 'off' }],
				},
				{
					featureType: 'landscape',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#fff0da',
						},
					],
				},
				{
					featureType: 'landscape.man_made',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#f2f2f2',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#daedff',
						},
					],
				},
			],
		}),
		[hideMapControls]
	);
	return (
		<GoogleMap
			mapContainerClassName={`w-full h-full`}
			options={options}
			{...props}
		/>
	);
};
