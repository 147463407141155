import React, { useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import markerSelectedImage from '../../images/booking/marker-selected.png';
import { parseDirectusWYSIWYG } from '../../util/utils';
import ImageCardLinkList from '../image-card-link-list/image-card-link-list';
import { TenantInitData } from '../../models/tenant-init-data';
import { BookingModal } from '../booking/booking-modal/booking-modal';
import { MapsApiLoader } from '../maps-api-loader/maps-api-loader';
import { MapComponent } from '../map/map-component';
import { GoogleAnalyticsEvenName, gtmPushEvent } from '../../util/gtm';
import { AddressMarker } from '../address-marker/address-marker';

type Props = {
	readonly il8nNext: ReturnType<typeof useI18next>;
	readonly tenant: Queries.TenantsFragment;
	readonly otherTenants: Readonly<Queries.TenantsFragment[]>;
	readonly backendTenants: Readonly<TenantInitData[]> | null;
};

export const TenantContent: React.FC<Props> = ({
	il8nNext,
	backendTenants,
	tenant,
	otherTenants,
}) => {
	const { t } = il8nNext;
	const [showBookingModal, setShowBookingModal] = useState<boolean>(false);

	const translation = tenant?.translations?.[0];

	const backendTenant = backendTenants?.find(
		(_backendTenant) => _backendTenant.id === tenant?.backend_tenant_id
	);

	return (
		<>
			{backendTenant && showBookingModal && (
				<BookingModal
					il8nNext={il8nNext}
					tenant={backendTenant}
					onClose={() => setShowBookingModal(false)}
				/>
			)}

			<section className={'relative mt-[60px] h-[50vh]'}>
				<div
					className={
						'absolute z-10 h-full w-full bg-gradient-to-t from-white to-65% '
					}
				/>
				<MapsApiLoader apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY ?? ''}>
					<MapComponent hideMapControls>
						{tenant?.address && (
							<AddressMarker
								centerMap
								address={tenant.address}
								icon={markerSelectedImage}
							/>
						)}
					</MapComponent>
				</MapsApiLoader>
			</section>
			<section>
				<div className={'container-custom pb-20 md:pb-14'}>
					{translation?.headline && (
						<h1 className={'h1 w-full md:text-start'}>
							{translation.headline}
						</h1>
					)}
					{translation?.subline && (
						<h2 className={'h3 w-full md:text-start'}>{translation.subline}</h2>
					)}
					<div className={'my-4 flex w-full justify-center md:justify-start'}>
						<button
							className={'button-primary px-12'}
							onClick={() => {
								gtmPushEvent(GoogleAnalyticsEvenName.TenantPageClickBookNow);

								setShowBookingModal(true);
							}}
						>
							{t('Book now')}
						</button>
					</div>
					{translation?.text && (
						<p className={'paragraph'}>
							{parseDirectusWYSIWYG(translation.text)}
						</p>
					)}
				</div>

				{otherTenants?.[0] && (
					<ImageCardLinkList
						headline={t('Other Tenants')}
						listData={otherTenants}
					/>
				)}
			</section>
		</>
	);
};
