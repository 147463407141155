import React from 'react';
import appStoreLogo from '../../images/app-store.svg';
import playStoreLogo from '../../images/play-store.svg';
import { GoogleAnalyticsEvenName, gtmPushEvent } from '../../util/gtm';

type StoreKey = keyof typeof stores;

const stores = {
	apple: {
		name: 'BeachNow App Store',
		logo: {
			src: appStoreLogo,
			width: 192,
			height: 40,
		},
	},
	google: {
		name: 'BeachNow Google Play Store',
		logo: {
			src: playStoreLogo,
			width: 646,
			height: 192,
		},
	},
};

type Props = {
	store: StoreKey;
	link: string | null | undefined;
};

export const StoreLink: React.FC<Props> = ({ store: storeKey, link }) => {
	if (!link) return null;
	const store = stores[storeKey];

	const onClick = () => {
		gtmPushEvent(
			storeKey === 'apple'
				? GoogleAnalyticsEvenName.AppStoreLinkClick
				: GoogleAnalyticsEvenName.GooglePlayStoreLinkClick
		);
	};

	return (
		<a
			href={link}
			target={'_blank'}
			className={'inline-flex h-10'}
			rel="noreferrer"
			onClick={onClick}
		>
			<img
				className={'h-full w-auto'}
				src={store.logo.src}
				width={store.logo.width}
				height={store.logo.height}
				alt={store.name}
			/>
		</a>
	);
};
