import React from 'react';

export const BookingLoading: React.FC = () => {
	return (
		<div className={`absolute z-10 flex w-full justify-center pt-4`}>
			<div className={`rounded-full bg-white p-1 shadow-md`}>
				<div
					className={`h-8 w-8 animate-spin rounded-full border-4 border-transparent border-t-primary`}
				></div>
			</div>
		</div>
	);
};
