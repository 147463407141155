import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

type Props = {
	readonly il8nNext: ReturnType<typeof useI18next>;
	readonly error: Error;
};

export const BookingError: React.FC<Props> = ({ il8nNext, error }) => {
	if (process.env.NODE_ENV !== 'development') {
		return null;
	}
	return (
		<div className={`absolute z-20 flex w-full justify-center p-4`}>
			<pre
				className={
					'mt-2 rounded-lg border border-red-500 bg-red-400 p-2 text-sm text-secondary shadow-lg'
				}
			>
				{il8nNext.t('Error')}: {error.message}
			</pre>
		</div>
	);
};
