export const DIRECTUS_ASSETS_URL = process.env.GATSBY_DIRECTUS_URL
	? `${process.env.GATSBY_DIRECTUS_URL}/assets/`
	: '';

const BACKEND_BASE_DOMAIN =
	process.env.GATSBY_BACKEND_BASE_DOMAIN ?? 'beachnow.de';
export const BACKEND_INIT_URL = `https://global.${BACKEND_BASE_DOMAIN}/backend/ConsumerApp/Init`;
export const BACKEND_TENANT_URL = (tenantId: string) =>
	`https://${tenantId}.${BACKEND_BASE_DOMAIN}/backend/ConsumerApp/Init/Tenant`;
export const BACKEND_ICON_URL = (tenantId: string, logoId: number) =>
	`https://${tenantId}.${BACKEND_BASE_DOMAIN}/backend/icon/${logoId}`;
export const BACKEND_BOOKING_URL = (tenantId: string) =>
	`https://${tenantId}.${BACKEND_BASE_DOMAIN}/booking/?beachChairs=true&backgroundColor=ffffff`;
