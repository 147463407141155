import * as React from 'react';
import { graphql } from 'gatsby';
import DirectusImage from '../directus-image/directus-image';
import AnimatedContainer from '../animated-container/animated-container';
import { parseDirectusWYSIWYG, scrollToContainer } from '../../util/utils';
import { footerId } from '../footer/footer';

export const query = graphql`
	fragment StartpageTenant on DirectusData_startpage {
		tenants_info_image {
			...Image
		}
		translations(
			filter: { languages_code: { code: { _eq: $language } } }
			limit: 1
		) {
			tenants_info_headline
			tenants_info_text
			tenants_info_button_text
		}
	}
`;

const StartpageTenantsInfo: React.FC<Queries.StartpageTenantFragment> = ({
	tenants_info_image: image,
	translations: translationsProp,
}) => {
	const translations = translationsProp && translationsProp[0];

	return (
		<section className={'py-container-custom grid'}>
			<AnimatedContainer className={'container-custom'}>
				<h2 className={'h1 text-center'}>
					{translations?.tenants_info_headline}
				</h2>
				{translations?.tenants_info_text && (
					<p className={'paragraph'}>
						{parseDirectusWYSIWYG(translations.tenants_info_text)}
					</p>
				)}
				<div className={'flex align-middle'}>
					<button
						onClick={() => scrollToContainer(footerId)}
						className={'button-primary m-button mx-auto inline-block px-12'}
					>
						{translations?.tenants_info_button_text}
					</button>
				</div>

				{image && (
					<DirectusImage className={'mt-10 w-full md:px-6'} image={image} />
				)}
			</AnimatedContainer>
		</section>
	);
};

export default StartpageTenantsInfo;
