import React from 'react';
import { BookingContent } from './booking-content';
import { BookingLoading } from '../booking-loading/booking-loading';
import { BookingError } from '../booking-error/booking-error';
import { useTenantInitData } from '../../../hooks/use-tenant-init-data';
import { useI18next } from 'gatsby-plugin-react-i18next';

type Props = {
	readonly il8nNext: ReturnType<typeof useI18next>;
};

export const BookingContentContainer: React.FC<Props> = ({ il8nNext }) => {
	const { data: tenants, loading, error } = useTenantInitData();
	return (
		<>
			{loading && <BookingLoading />}
			{error instanceof Error && (
				<BookingError il8nNext={il8nNext} error={error} />
			)}
			{tenants && <BookingContent il8nNext={il8nNext} tenants={tenants} />}
		</>
	);
};
