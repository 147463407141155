export enum GoogleAnalyticsEvenName {
	BookingPageClickBookNow = 'booking_page_book_now_click',
	TenantPageClickBookNow = 'tenant_page_book_now_click',
	HeaderNavigationClickBookNow = 'header_book_now_click',
	AppStoreLinkClick = 'app_store_link_click',
	GooglePlayStoreLinkClick = 'google_play_store_link_click',
}

export const gtmPushEvent = (eventName: string) => {
	if (typeof window !== 'undefined') {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		window.dataLayer = window.dataLayer || [];
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
		dataLayer.push({ event: eventName });
	}
};
