import { useContext, useEffect, useState } from 'react';
import { TenantInitData } from '../models/tenant-init-data';
import { TenantLoaderContext } from '../components/booking/tenant-loader-provider/tenant-loader-provider';

type UseTenantInitDataResult = {
	data: TenantInitData[] | null;
	error: unknown;
	loading: boolean;
};

export const useTenantInitData = (): UseTenantInitDataResult => {
	const [data, setData] = useState<TenantInitData[] | null>(null);
	const [error, setError] = useState<unknown>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const tenantLoader = useContext(TenantLoaderContext);
	useEffect(() => {
		const controller = new AbortController();
		setLoading(true);
		tenantLoader
			.fetchInitData()
			.then((tenants) => {
				if (!controller.signal.aborted) {
					setData(tenants);
				}
			})
			.catch(setError)
			.finally(() => setLoading(false));
		return () => controller.abort();
	}, [tenantLoader]);

	return { data, error, loading };
};
