import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout/layout';
import { PageHead } from '../components/page-head/page-head';
import { PageContext } from '../types/page-context';

export const query = graphql`
	query NotFoundPage($language: String!) {
		directus {
			header {
				...Header
			}
			legals {
				...Legals
			}
		}
		locales: allLocale(filter: { ns: { in: ["common"] } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

const NotFoundPage: React.FC<PageProps<Queries.NotFoundPageQuery>> = ({
	data,
}) => {
	const { legals, header } = data.directus;
	const il8nNext = useI18next();
	const { t } = il8nNext;

	return (
		<Layout il8nNext={il8nNext} legals={legals} header={header}>
			<section
				className={
					'flex h-screen w-screen flex-col items-center justify-center bg-primary'
				}
			>
				<h1 className={'h1 text-white'}>{t('Page not found')}</h1>
			</section>
		</Layout>
	);
};

export default NotFoundPage;

export const Head: React.FC<
	PageProps<Queries.NotFoundPageQuery, PageContext>
> = ({ pageContext }) => {
	const { t } = useI18next();
	const { language } = pageContext;

	return (
		<PageHead
			title={`BeachNow 404 – ${t('Page not found')}`}
			description={`BeachNow 404 – ${t('Page not found')}`}
			language={language}
		/>
	);
};
