import React, { ReactElement } from 'react';
import { theme } from '../../../tailwind.config';

interface Props {
	title: string;
	description: string;
	language: string;
	additionalHtml?: ReactElement<HTMLElement>;
}

export const PageHead: React.FC<Props> = ({
	title,
	description,
	language,
	additionalHtml,
}) => {
	return (
		<>
			<html lang={language} />
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta
				name="google-site-verification"
				content="HyfVOkoAUR78FzmfzDv4yRSawZTM0Yz9JQGbe4L654g"
			/>
			<meta name="theme-color" content={theme.extend.colors.primary} />
			{additionalHtml}
		</>
	);
};
