import React, { PropsWithChildren, useRef } from 'react';
import { useInView } from 'framer-motion';

interface Props {
	className?: React.ComponentProps<'div'>['className'];
}

const AnimatedContainer: React.FC<PropsWithChildren<Props>> = ({
	className,
	children,
}) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });

	return (
		<div
			style={{
				transform: isInView ? 'translateY(0)' : 'translateY(150px)',
				opacity: isInView ? 1 : 0,
				transition:
					'opacity 0.75s cubic-bezier(0.17, 0.55, 0.55, 1) 0.125s,' +
					'transform 0.75s cubic-bezier(0.17, 0.55, 0.55, 1) 0.125s',
			}}
			ref={ref}
			className={className}
		>
			{children}
		</div>
	);
};

export default AnimatedContainer;
