import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageHead } from '../components/page-head/page-head';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout/layout';
import { TenantLoaderProvider } from '../components/booking/tenant-loader-provider/tenant-loader-provider';
import { BookingContentContainer } from '../components/booking/booking-content/booking-content-container';
import { PageContext } from '../types/page-context';

export const query = graphql`
	query BookingPage($language: String!) {
		directus {
			booking {
				page {
					...Pages
				}
			}
			header {
				...Header
			}
			legals {
				...Legals
			}
		}
		locales: allLocale(filter: { ns: { in: ["common", "booking"] } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

const Booking: React.FC<PageProps<Queries.BookingPageQuery>> = ({ data }) => {
	const il8nNext = useI18next();
	const {
		directus: { header, legals },
	} = data;

	return (
		<Layout
			il8nNext={il8nNext}
			legals={legals}
			header={header}
			headerShowBookingButton={false}
		>
			<div className={`min-h-booking`}>
				<TenantLoaderProvider>
					<BookingContentContainer il8nNext={il8nNext} />
				</TenantLoaderProvider>
			</div>
		</Layout>
	);
};

export default Booking;

export const Head: React.FC<
	PageProps<Queries.BookingPageQuery, PageContext>
> = ({ data, pageContext }) => {
	const page = data.directus.booking?.page;
	const { language } = pageContext;

	return (
		<>
			{page ? (
				<PageHead
					title={page.translations?.[0]?.title ?? ''}
					description={page.translations?.[0]?.description ?? ''}
					language={language}
				/>
			) : null}
			<body className={`bg-white`} />
		</>
	);
};
