import parse, {
	attributesToProps,
	domToReact,
	Element,
	HTMLReactParserOptions,
} from 'html-react-parser';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

const parseDirectusWYSIWYGOptions: HTMLReactParserOptions = {
	replace: (domNode) => {
		// remove <p>
		if (domNode instanceof Element && domNode.attribs && domNode.name === 'p') {
			const props = attributesToProps({
				...domNode.attribs,
				style: '',
				class: 'block',
			});

			return (
				<span {...props}>
					{domToReact(domNode.children, parseDirectusWYSIWYGOptions)}
				</span>
			);
		}

		// style <a>
		if (domNode instanceof Element && domNode.attribs && domNode.name === 'a') {
			const props = attributesToProps({
				...domNode.attribs,
				style: '',
				class: 'underline',
			});
			return (
				<a {...props}>
					{domToReact(domNode.children, parseDirectusWYSIWYGOptions)}
				</a>
			);
		}

		// style <h2>
		if (
			domNode instanceof Element &&
			domNode.attribs &&
			domNode.name === 'h2'
		) {
			const props = attributesToProps({
				...domNode.attribs,
				class: 'h2 block w-full text-primary mb-2 pt-4',
			});
			return (
				<h2 {...props}>
					{domToReact(domNode.children, parseDirectusWYSIWYGOptions)}
				</h2>
			);
		}

		// style <ul>
		if (
			domNode instanceof Element &&
			domNode.attribs &&
			domNode.name === 'ul'
		) {
			const props = attributesToProps({
				...domNode.attribs,
				class: 'my-4',
			});
			return (
				<ul {...props}>
					{domToReact(domNode.children, parseDirectusWYSIWYGOptions)}
				</ul>
			);
		}

		// style <ul>
		if (
			domNode instanceof Element &&
			domNode.attribs &&
			domNode.name === 'li'
		) {
			const props = attributesToProps({
				...domNode.attribs,
				style: 'list-style-type: disc; margin-left: 32px',
			});
			return (
				<li {...props}>
					{domToReact(domNode.children, parseDirectusWYSIWYGOptions)}
				</li>
			);
		}

		// remove <div>
		if (
			domNode instanceof Element &&
			domNode.attribs &&
			domNode.name === 'div'
		) {
			return <>{domToReact(domNode.children, parseDirectusWYSIWYGOptions)}</>;
		}

		// remove styles
		if (
			domNode instanceof Element &&
			domNode.attribs &&
			!!domNode.attribs.style
		) {
			return <>{domToReact(domNode.children, parseDirectusWYSIWYGOptions)}</>;
		}
	},
};

export const parseDirectusWYSIWYG = (htmlString: string) =>
	parse(htmlString, parseDirectusWYSIWYGOptions);

export const scrollToContainer = (id: string) =>
	document.getElementById(id)?.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	});
